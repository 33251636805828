html,
body {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  background: #fbf9f8;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  font-family: 'Basis Grotesque Pro';
}
.mainApp {
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: fixed;
  background: inherit;
  width: 100%;
  height: 101px;
  padding: 0px 70px 0px;
}
.selected {
  color: yellow;
}
.content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  align-items: flex-start;
  margin: 0px 71px 128px 71px;
  min-height: 100%;
  overflow-x: visible;
  max-width: 1440px;
  
}
.mainContent {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  align-items: flex-start;
  background: white;
  overflow-x: visible;
  overflow: auto;
  max-width: 1440px;
  width: 100%;
  /* kishan - adding 1850px for MapHome fixes the issue. */
  /* min-height: 100vh; */
}
.contact {
  min-height: 899px;
  width: 100%;
  align-content: center;
  justify-content: center;
  display: flex;
  height: 90vh;
}
.footer {
  height: 284px;
  position: sticky;
  display: flex;
  flex-direction: row;
  bottom: 0px;
  flex-grow: 1;
  margin-top: -100px;
  left: 0;
  right: 0;
  clear: both;
}
.contact-content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  align-items: flex-start;
  margin: 101px 0px 0px 0px;
  min-height: 100%;
  overflow-x: visible;
  z-index: 0;
}
.mainFooter {
  width: 100%;
  height: 85px;
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  
  left: 0;
  right: 0;
  clear: both;
  background-color: #26503e;
}
.landing {
  min-height: 4692px;
  max-width: 1440px;
}

.focusImg {
  display: none;
}

.frameOverview:focus {
  background: url(./Components/Images/Header/OVERVIEW.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameOverview:hover {
  background: url(./Components/Images/Header/OVERVIEW.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameOverview {
  text-decoration: inherit;
  color: inherit;
  font-size: 16;
  font-weight: bold;
  flex-grow: 1;
  padding-left: 20px;
  height: 35px;
  z-index: 1;
  font-family: Verdana !important;
}
.frameMapIt:focus {

  background: url(./Components/Images/Header/focusMapItN.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameMapIt:hover {

  background: url(./Components/Images/Header/focusMapItN.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameMapIt {
  text-decoration: inherit;
  color: inherit;
  font-size: 16;
  font-weight: bold;
  flex-grow: 1;
  padding-left: 20px;
  height: 35px;
  /* padding-right: 20px; */
  z-index: 1;
  font-family: Verdana !important;

}
.frameTrackIt:focus {
  background: url(./Components/Images/Header/TRACK_IT.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameTrackIt:hover {
  background: url(./Components/Images/Header/TRACK_IT.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameTrackIt {
  text-decoration: inherit;
  color: inherit;
  font-size: 16;
  font-weight: bold;
  flex-grow: 1;
  padding-left: 20px;
  height: 35px;
  z-index: 1;
  font-family: Verdana !important;

}
.frameTellIt:focus {
  background: url(./Components/Images/Header/focusTellItN.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameTellIt:hover {
  background: url(./Components/Images/Header/focusTellItN.svg);
  background-repeat: no-repeat;
  background-origin: content-box;
 
}
.frameTellIt {
  text-decoration: inherit;
  color: inherit;
  font-size: 16;
  font-weight: bold;
  flex-grow: 1;
  padding-left: 20px;
  height: 35px;
  z-index: 1;
  font-family: Verdana !important;

}
.pinkBackground {
  background: url('./Components/Images/TrackIt/TrackStep1/bg_pink_tooltip.svg');
  top: 10px;
}
.mapHome1 {
  -ms-overflow-style: 'none'
}
.hide {
  display: none;
}
.mapHomeImage:hover + .hide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16;
  line-height: 24;
  width: 170px;
  height: 124px;
  color:black;
}
.text {
  display: flex;
  text-align: center;
  justify-content: center;
  color: black;
  font-weight: 700;
  font-variant: body;
}
.li {
  line-height: 1em;
  margin: 8px 0px 0px 0px;
  align-items: flex-start;
  font-size: 16;

}
/* Track It Step 2 Boxes */
.box1 {
  height: 40px;
  background: #E3E5E9;
  border-radius: 20px;
  margin: 8px 0;
  padding: 10px;
  cursor: pointer; 
}
.selected {
  height: 40px;
  background: #E3E5E9;
  border-radius: 20px;
  margin: 8px 0;
  padding: 10px;
  cursor: pointer; 
  background-color: #BBBECC;
}
.box1:hover {
  background: #BCDDF7
}
.box1:focus {
  background: #BCDDF7
}

.trackItDropdown {
  border: 2px solid black;
  color: #656C88;
  box-sizing: border-box;
  border-radius: 8px;
}



